var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('q-table',{ref:"tabl",staticClass:"table-records",class:{ 'is-empty': _vm.isEmpty, 'is-add': _vm.isAdd },attrs:{"dense":"","data":_vm.data,"columns":_vm.columns,"hide-bottom":true,"row-key":row => row.index,"pagination":_vm.pagination},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.isEmpty)?_c('div',{staticClass:"empty"},[_vm._v("Pas d'information disponible")]):_vm._e(),(_vm.isSelect)?_c('Selection',{attrs:{"entity-id":_vm.entityId}}):_vm._e()]},proxy:true},{key:"top-row",fn:function(){return [_c('LoadingOrEmpty',{attrs:{"loading":_vm.getLoadingEntitiesData && !_vm.loadInfinity,"custom-empty":true},scopedSlots:_vm._u([{key:"customEmpty",fn:function(){return [_c('div')]},proxy:true}])}),(_vm.isAdd)?_c('NewInfo'):_vm._e()]},proxy:true},{key:"body",fn:function(props){return [(props.row && !props.row.isEdit)?_c('q-tr',{class:{
          'is-scroll-info':
            _vm.localInformationName.trim() === props.row.dataLabel.trim()
        },attrs:{"props":props},on:{"click":function($event){_vm.isSelect
            ? _vm.toggleChecked(props.row.dataId)
            : _vm.edit(props.row.dataId, props.row)}}},[_c('q-td',{key:"dataLabel",staticClass:"title td-data-value",attrs:{"props":props}},[_c('span',{class:props.row.status},[(props.row.status === 'MANUAL')?_c('q-tooltip',{attrs:{"content-class":"status"}},[_vm._v(" Informations vérifiée ")]):_vm._e(),(props.row.status === 'LOW')?_c('q-tooltip',{attrs:{"content-class":"status"}},[_vm._v(" Confiance moyenne ")]):_vm._e(),(props.row.status === 'HIGH')?_c('q-tooltip',{attrs:{"content-class":"status"}},[_vm._v(" Confiance élevée ")]):_vm._e()],1),_c('span',[_vm._v(" "+_vm._s(_vm._f("tooManyInformation")(props.row.dataLabel))+" "),(props.row.dataLabel.length > _vm.maxLengthInformation)?_c('q-tooltip',[_vm._v(" "+_vm._s(props.row.dataLabel)+" ")]):_vm._e()],1)]),_c('q-td',{key:"dataValue",staticClass:"td-info-value",attrs:{"props":props}},[(_vm.isSelect)?_c('q-checkbox',{model:{value:(props.row.isChecked),callback:function ($$v) {_vm.$set(props.row, "isChecked", $$v)},expression:"props.row.isChecked"}}):_vm._e(),_vm._v(" "+_vm._s(_vm._f("tooManyInformationValue")(_vm._f("reformatIsMoney")(props.row.dataValue,props.row.isMoney)))+" "),(
              props.row.dataValue.length >
                _vm.maxLengthInformation - _vm.fixedValueLength
            )?_c('q-tooltip',[_vm._v(" "+_vm._s(props.row.dataValue)+" ")]):_vm._e()],1),_c('q-td',{key:"sourceDocumentType",staticClass:"td-info-type",attrs:{"props":props}},[_vm._v(" "+_vm._s(props.row.sourceDocumentType)+" ")]),_c('q-td',{key:"sourceDocumentDate",staticClass:"td-update",attrs:{"props":props}},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(props.row.sourceDocumentDate)+" ")]),_c('div',{staticClass:"actions"},[(
                props.row.sourceDocumentId &&
                  props.row.sourceDocumentId != '0'
              )?_c('q-icon',{staticClass:"table-eye-icon",on:{"click":e =>
                  _vm.openPDF(
                    e,
                    props.row.dataId,
                    props.row.sourceDocumentId,
                    _vm.entityId
                  )}}):_vm._e(),(!_vm.isSelect)?_c('q-icon',{staticClass:"table-edit-icon"}):_vm._e(),_c('q-icon',{staticClass:"table-copy-icon",on:{"click":e => _vm.copy(e, props.row.dataValue)}},[_c('q-tooltip',{attrs:{"content-class":"status"}},[_vm._v(" Copier les informations ")])],1),_c('q-icon',{staticClass:"table-trash-icon",on:{"click":e => _vm.remove(e, props.row.dataId)}})],1)])],1):_vm._e(),(props.row && props.row.isEdit)?_c('EditInfo',{key:props.row.dataId !== '' ? props.row.dataId : Math.round(),attrs:{"props":props,"editLabelDone":_vm.editLabelDone,"is-deleted":_vm.isDeleteVariant,"edit-item":_vm.editItem},on:{"openPDF":_vm.openPreviewPdf,"removedVariant":_vm.removedVariant,"editVariant":_vm.toggleEditVariant,"edit":_vm.edit,"closeEdit":_vm.closeEdit,"saveLabel":_vm.saveLabel,"resetEditLabelDone":_vm.resetEditLabelDone}}):_vm._e()]}},{key:"bottom-row",fn:function(){return [(_vm.infinity)?_c('infinite-loading',{on:{"infinite":_vm.infiniteHandler}}):_vm._e()]},proxy:true}])}),(_vm.isDelete)?[_c('DeleteModal',{attrs:{"content":_vm.contentDeleteInfo,"is-show":_vm.isDelete},on:{"update:content":function($event){_vm.contentDeleteInfo=$event},"update:isShow":function($event){_vm.isDelete=$event},"update:is-show":function($event){_vm.isDelete=$event},"closeModal":_vm.closeDeleteModal,"removed":_vm.removed}})]:_vm._e(),(_vm.isPreviewPdf)?[_c('PdfBlock',{attrs:{"document":_vm.document,"extractedData":_vm.extractedData,"isInputUse":false,"isPreviewPdf":true,"isShowInstructions":false,"selectedDataId":_vm.selectedDataId,"selectedEntityId":_vm.selectedEntityId},on:{"close":_vm.closePreviewPdf}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }