<template>
  <div
    v-show="show"
    class="selection"
    ref="selection"
    :style="{ 'margin-left': `${margin}px` }"
  >
    <q-checkbox v-model="selectAll" label="Tout sélectionner" />
    <q-icon class="table-copy-icon" @click="copy">
      <q-tooltip content-class="status">
        Copier les informations
      </q-tooltip>
    </q-icon>
    <q-icon class="table-trash-icon" @click="remove"></q-icon>
    <DeleteModal
      :content.sync="contentDeleteItems"
      :is-show.sync="isDelete"
      @closeModal="closeDeleteModal"
      @removed="removed"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
const DeleteModal = () => import('@/components/modals/Delete')

export default {
  components: { DeleteModal },
  props: {
    entityId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false,
      margin: 0,
      selectAll: null,
      isDelete: false,
      deleteItems: [],
      contentDeleteItems: {
        title: "Supprimer l'information",
        text: [
          'Êtes-vous sûr de vouloir supprimer cette information ? ',
          'Vous ne pourrez pas annuler cette action.'
        ]
      }
    }
  },
  mounted() {
    setTimeout(() => {
      const position = this.$refs.selection
        .closest('.q-table__container')
        .querySelector('.q-table .q-checkbox')
        .getBoundingClientRect()
      this.margin = position.left - 106
      this.show = true
    }, 0)
  },
  computed: {
    ...mapGetters('projects', ['getEntitiesData', 'getErrorRemovingEntityItem'])
  },
  methods: {
    ...mapActions('projects', ['setEntityData', 'removingEntityItem']),
    copy() {
      const text =
        this.getEntitiesData[this.entityId].reduce((total, item) => {
          if (item.isChecked) {
            total += !total.length ? item.dataValue : '\n' + item.dataValue
          }

          return total
        }, '') || ''

      if (!text.length) {
        this.showNotify(`Aucune information sélectionné`)
        return
      }

      const input = document.createElement('textarea')
      document.body.appendChild(input)
      input.value = text
      input.focus()
      input.select()
      document.execCommand('copy')
      input.remove()
    },
    remove() {
      this.deleteItems =
        this.getEntitiesData[this.entityId].filter(
          item => item.isChecked === true
        ) || []
      if (!this.deleteItems.length) {
        this.showNotify(`Aucune information sélectionné`)
        return
      }

      this.isDelete = true
    },
    removed() {
      this.deleteItems.forEach(async item => {
        await this.removingEntityItem({
          entityId: this.entityId,
          dataId: item.dataId
        })

        if (this.getErrorRemovingEntityItem.length) return

        const result =
          this.getEntitiesData[this.entityId].filter(
            entity => entity.dataId !== item.dataId
          ) || []
        this.setEntityData({ entityId: this.entityId, data: [...result] })
      })

      if (this.getErrorRemovingEntityItem.length) return

      this.isDelete = false
    },
    closeDeleteModal() {
      this.isDelete = false
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    }
  },
  watch: {
    selectAll: function(value) {
      const result =
        this.getEntitiesData[this.entityId].map(item => {
          item.isChecked = value
          return item
        }) || []

      this.setEntityData({ entityId: this.entityId, data: [...result] })
    },
    getErrorRemovingEntityItem: function(message) {
      if (message && message.length) this.showNotify(message)
    }
  }
}
</script>
