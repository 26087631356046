import { render, staticRenderFns } from "./TableCompany.vue?vue&type=template&id=679ec0e7&scoped=true&"
import script from "./TableCompany.vue?vue&type=script&lang=js&"
export * from "./TableCompany.vue?vue&type=script&lang=js&"
import style0 from "./TableCompany.vue?vue&type=style&index=0&id=679ec0e7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "679ec0e7",
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QTr,QTd,QTooltip,QCheckbox,QIcon});
