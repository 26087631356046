<template>
  <div>
    <q-table
      dense
      ref="tabl"
      :data="data"
      :columns="columns"
      :hide-bottom="true"
      :row-key="row => row.index"
      class="table-records"
      :pagination.sync="pagination"
      :class="{ 'is-empty': isEmpty, 'is-add': isAdd }"
    >
      <template v-slot:top>
        <div v-if="isEmpty" class="empty">Pas d'information disponible</div>
        <Selection v-if="isSelect" :entity-id="entityId" />
      </template>

      <template v-slot:top-row>
        <LoadingOrEmpty
          :loading="getLoadingEntitiesData && !loadInfinity"
          :custom-empty="true"
        >
          <template v-slot:customEmpty>
            <div></div>
          </template>
        </LoadingOrEmpty>
        <NewInfo v-if="isAdd" />
      </template>

      <template v-slot:body="props">
        <q-tr
          :props="props"
          v-if="props.row && !props.row.isEdit"
          :class="{
            'is-scroll-info':
              localInformationName.trim() === props.row.dataLabel.trim()
          }"
          @click="
            isSelect
              ? toggleChecked(props.row.dataId)
              : edit(props.row.dataId, props.row)
          "
        >
          <q-td key="dataLabel" :props="props" class="title td-data-value">
            <span :class="props.row.status">
              <q-tooltip
                content-class="status"
                v-if="props.row.status === 'MANUAL'"
              >
                Informations vérifiée
              </q-tooltip>
              <q-tooltip
                content-class="status"
                v-if="props.row.status === 'LOW'"
              >
                Confiance moyenne
              </q-tooltip>
              <q-tooltip
                content-class="status"
                v-if="props.row.status === 'HIGH'"
              >
                Confiance élevée
              </q-tooltip>
            </span>
            <span>
              {{ props.row.dataLabel | tooManyInformation }}
              <q-tooltip
                v-if="props.row.dataLabel.length > maxLengthInformation"
              >
                {{ props.row.dataLabel }}
              </q-tooltip>
            </span>
          </q-td>
          <q-td key="dataValue" :props="props" class="td-info-value">
            <q-checkbox v-if="isSelect" v-model="props.row.isChecked" />
            {{
              props.row.dataValue
                | reformatIsMoney(props.row.isMoney)
                | tooManyInformationValue
            }}
            <q-tooltip
              v-if="
                props.row.dataValue.length >
                  maxLengthInformation - fixedValueLength
              "
            >
              {{ props.row.dataValue }}
            </q-tooltip>
          </q-td>
          <q-td key="sourceDocumentType" :props="props" class="td-info-type">
            {{ props.row.sourceDocumentType }}
          </q-td>
          <q-td key="sourceDocumentDate" :props="props" class="td-update">
            <div class="date">
              {{ props.row.sourceDocumentDate }}
            </div>
            <div class="actions">
              <q-icon
                class="table-eye-icon"
                v-if="
                  props.row.sourceDocumentId &&
                    props.row.sourceDocumentId != '0'
                "
                @click="
                  e =>
                    openPDF(
                      e,
                      props.row.dataId,
                      props.row.sourceDocumentId,
                      entityId
                    )
                "
              ></q-icon>
              <q-icon v-if="!isSelect" class="table-edit-icon"></q-icon>
              <q-icon
                class="table-copy-icon"
                @click="e => copy(e, props.row.dataValue)"
              >
                <q-tooltip content-class="status">
                  Copier les informations
                </q-tooltip>
              </q-icon>
              <q-icon
                class="table-trash-icon"
                @click="e => remove(e, props.row.dataId)"
              ></q-icon>
            </div>
          </q-td>
        </q-tr>
        <EditInfo
          @openPDF="openPreviewPdf"
          :props="props"
          v-if="props.row && props.row.isEdit"
          @removedVariant="removedVariant"
          @editVariant="toggleEditVariant"
          @edit="edit"
          @closeEdit="closeEdit"
          @saveLabel="saveLabel"
          @resetEditLabelDone="resetEditLabelDone"
          :editLabelDone="editLabelDone"
          :key="props.row.dataId !== '' ? props.row.dataId : Math.round()"
          :is-deleted="isDeleteVariant"
          :edit-item="editItem"
        />
      </template>

      <template v-slot:bottom-row>
        <infinite-loading
          v-if="infinity"
          @infinite="infiniteHandler"
        ></infinite-loading>
      </template>
    </q-table>
    <template v-if="isDelete">
      <DeleteModal
        :content.sync="contentDeleteInfo"
        :is-show.sync="isDelete"
        @closeModal="closeDeleteModal"
        @removed="removed"
      />
    </template>
    <template v-if="isPreviewPdf">
      <PdfBlock
        :document="document"
        @close="closePreviewPdf"
        :extractedData="extractedData"
        :isInputUse="false"
        :isPreviewPdf="true"
        :isShowInstructions="false"
        :selectedDataId="selectedDataId"
        :selectedEntityId="selectedEntityId"
      />
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions, mapState } from 'vuex'
const DeleteModal = () => import('@/components/modals/Delete')
const NewInfo = () => import('@/components/project/NewInfo')
const EditInfo = () => import('@/components/project/EditInfo')
import Selection from '@/components/project/Selection.vue'
import InfiniteLoading from 'vue-infinite-loading'
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty'
const PdfBlock = () => import('@/components/global/PdfBlock')

export default {
  name: 'TableCompany',
  components: {
    LoadingOrEmpty,
    InfiniteLoading,
    EditInfo,
    Selection,
    NewInfo,
    DeleteModal,
    PdfBlock
  },
  props: {
    isAdd: {
      type: Boolean,
      default: false
    },
    isSelect: {
      type: Boolean,
      default: false
    },
    entityId: {
      type: String,
      default: ''
    },
    changeYear: {
      type: Number,
      default: 0
    },
    updatePaginationAndInfinity: {
      type: Boolean,
      default: false
    },
    entityFilter: {
      type: Object,
      default: () => {}
    },
    informationName: {
      type: String,
      default: ''
    },
    selectedEntity: {
      type: String,
      default: ''
    }
  },
  data() {
    var window_width = window.innerWidth

    this.maxLengthInfo = 80

    if (window_width < 1536) {
      this.valueLength = 50
    } else if (window_width >= 1536 && window_width < 1920) {
      this.valueLength = 25
    } else if (window_width >= 1920 && window_width < 2560) {
      this.valueLength = 0
    } else {
      this.valueLength = -25
    }

    return {
      isSearch: false,
      filter: this.entityFilter,
      entitiesBySearch: [],
      localInformationName: '',
      columns: [
        { name: 'dataLabel', label: 'Intitulé', align: 'left', field: 'index' },
        { name: 'dataValue', label: 'Informations extraites', align: 'left' },
        { name: 'sourceDocumentType', label: 'Sources', align: 'left' },
        { name: 'sourceDocumentDate', label: 'Date', align: 'left' }
      ],
      pagination: {
        rowsPerPage: null
      },
      maxLengthInformation: this.maxLengthInfo,
      contentDeleteInfo: {
        title: 'Supprimer l’information',
        text: [
          'Êtes-vous sûr de vouloir supprimer cette information ?',
          'Vous ne pourrez pas annuler cette action.'
        ],
        id: null,
        removeUrl: ''
      },
      isEdit: false,
      isDelete: false,
      isDeleteVariant: false,
      yearFilter: false,
      infinity: false,
      loadInfinity: false,
      margin: 0,
      editItem: {},
      editLabelDone: false,
      fixedValueLength: this.valueLength,
      isPreviewPdf: false,
      selectedDataId: null,
      extractedData: [],
      document: null
    }
  },
  computed: {
    ...mapState('documents', ['loadingDocument', 'errorDocument']),
    ...mapGetters('projects', [
      'getEntitiesData',
      'getLoadingEntitiesData',
      'getErrorEntitiesData',
      'getFilter',
      'getErrorRemovingEntityItem',
      'getErrorPutEntityItem',
      'getFilterNextTokens',
      'getSearch'
    ]),
    ...mapGetters('documents', [
      'getReviewDocument',
      'getDocument',
      'getLoadingDocument'
    ]),
    data() {
      if (this.isSearch) {
        // eslint-disable-next-line
        this.pagination.rowsPerPage =
          this.entitiesBySearch[this.entityId]?.length || 0
      } else {
        // eslint-disable-next-line
        this.pagination.rowsPerPage =
          this.getEntitiesData[this.entityId]?.length || 0
      }

      let _data = this.getEntitiesData[this.entityId]

      if (_data && _data.length)
        _data.forEach((entity, index) => {
          if (entity.dataId === '') {
            entity.dataId = entity.variants[0]?.dataId
            entity.dataValue = entity.variants[0]?.dataValue
            entity.sourceDocumentDate = entity.variants[0]?.sourceDocumentDate
            entity.sourceDocumentType = entity.variants[0]?.sourceDocumentType
            entity.sourceDocumentId = entity.variants[0]?.sourceDocumentId
            entity.status = entity.variants[0]?.status
            entity.dataDefault = entity.variants[0]?.dataDefault
          }
          entity.index = index
        })

      return _data && _data.length ? _data : []
    },
    isEmpty() {
      let empty = false

      if (
        this.isSearch &&
        this.entitiesBySearch &&
        this.entitiesBySearch[this.entityId]
      ) {
        empty = this.entitiesBySearch[this.entityId].length === 0
        return empty
      }

      if (
        !this.isSearch &&
        this.getEntitiesData &&
        this.getEntitiesData[this.entityId]
      ) {
        empty = this.getEntitiesData[this.entityId].length === 0
        return empty
      }

      return empty
    }
  },
  async created() {
    window.addEventListener('resize', this.windowResizeListener)
    if (this.entityFilter['filterDataCodes'].length == 0 & this.entityFilter['filterDataConfidences'].length == 0 & this.entityFilter['filterDataDocumentYears'].length == 0 & this.entityFilter['filterDataSources'].length == 0) {
      await this.fetchEntitiesData({
        entityId: this.entityId,
        filter: Object.values(this.filter).length ? this.filter : this.getFilter
      }).then(() => {
        this.pagination.rowsPerPage = this.getEntitiesData[this.entityId].length
        this.infinity =
          this.getFilterNextTokens[this.entityId] &&
          Object.keys(this.getFilterNextTokens[this.entityId]).length
      })
    }

    if (this.getSearch) {
      this.buildSearchData(this.getSearch)
    }

    // this.$emit('resetEntityFilter')
  },
  async destroyed() {
    window.removeEventListener('resize', this.windowResizeListener)
  },
  methods: {
    ...mapActions('projects', [
      'fetchEntitiesData',
      'removingEntityItem',
      'fetchEntitiesProject',
      'putEntityItem',
      'setEntityData'
    ]),
    ...mapActions('documents', ['fetchDocument']),
    closeDeleteModal() {
      this.isDelete = false
    },
    windowResizeListener(e) {
      var window_width = e.currentTarget.innerWidth

      this.maxLengthInfo = 80

      if (window_width < 1536) {
        this.fixedValueLength = 50
      } else if (window_width >= 1536 && window_width < 1920) {
        this.fixedValueLength = 25
      } else if (window_width >= 1920 && window_width < 2560) {
        this.fixedValueLength = 0
      } else {
        this.fixedValueLength = -25
      }
    },
    async closePreviewPdf() {
      this.isPreviewPdf = false
      await this.fetchEntitiesData({
        entityId: this.entityId,
        filter: Object.values(this.filter).length ? this.filter : this.getFilter
      }).then(() => {
        this.pagination.rowsPerPage = this.getEntitiesData[this.entityId].length
        this.infinity =
          this.getFilterNextTokens[this.entityId] &&
          Object.keys(this.getFilterNextTokens[this.entityId]).length
      })

      if (this.getSearch) {
        this.buildSearchData(this.getSearch)
      }

      this.$emit('resetEntityFilter')
    },
    async edit(id, status = true, originDataDefault = false, noClose = false) {
      if (!status) {
        const variants = this.getEntitiesData[this.entityId].find(item =>
          item.variants.find(variant => variant.dataId === id)
        )
        const entity = Object.keys(this.editItem).length
          ? this.editItem
          : variants.variants.find(item => item.dataId === id)

        const {
          dataValue,
          sourceDocumentDate,
          sourceDocumentType,
          dataId,
          dataDefault
        } = entity

        const data = {
          dataId,
          entityId: this.entityId,
          dataValue,
          dataDefault: originDataDefault ? dataDefault : true,
          sourceDocumentDate,
          sourceDocumentType
        }

        await this.putEntityItem(data)
        if (this.getErrorPutEntityItem.length) return

        let index, dataForUpdate

        index = this.getEntitiesData[this.entityId].findIndex(
          entity => entity.dataId === variants.dataId
        )
        dataForUpdate = [...this.getEntitiesData[this.entityId]]

        this.$emit('updateDataDone', data.sourceDocumentDate)

        if (!entity.isEdit) {
          dataForUpdate[index] = {
            ...variants,
            dataValue: data.dataValue,
            dataId: data.dataId,
            sourceDocumentDate: data.sourceDocumentDate,
            sourceDocumentType: data.sourceDocumentType,
            isEdit: noClose
          }

          this.editItem = {}
        } else {
          dataForUpdate[index].variants = dataForUpdate[index].variants.map(
            variant => {
              variant.dataDefault = false
              return variant
            }
          )

          const variantsIndex = dataForUpdate[index].variants.findIndex(
            variant => variant.dataId === entity.dataId
          )

          dataForUpdate[index] = {
            ...variants,
            dataValue: data.dataValue,
            dataId: data.dataId,
            sourceDocumentDate: data.sourceDocumentDate,
            sourceDocumentType: data.sourceDocumentType,
            status: 'MANUAL'
          }

          dataForUpdate[index].variants[variantsIndex] = {
            ...entity,
            isEdit: noClose,
            dataValue: data.dataValue,
            dataDefault: true,
            sourceDocumentDate: data.sourceDocumentDate,
            sourceDocumentType: data.sourceDocumentType
          }
        }

        this.setEntityData({ entityId: this.entityId, data: dataForUpdate })
      } else {
        let result = []

        result = this.getEntitiesData[this.entityId].map(item => {
          item.variants = item.variants
            .map(variant => {
              variant.isEdit = false

              if (item.dataId === id && variant.dataDefault) {
                variant.isEdit = false
                this.editItem = { ...variant }
              }

              return variant
            })
            .sort((a, b) => (a.isEdit > b.isEdit ? -1 : 1))

          item.isEdit = item.dataId === id

          return item
        })
        this.setEntityData({ entityId: this.entityId, data: [...result] })
      }
    },
    closeEdit(id) {
      const index = this.getEntitiesData[this.entityId].findIndex(
        item => item.dataId === id
      )
      const data = [...this.getEntitiesData[this.entityId]]
      data[index] = { ...data[index], isEdit: false }
      this.setEntityData({ entityId: this.entityId, data })
    },
    remove(e, id) {
      e.stopPropagation()
      this.contentDeleteInfo.id = id
      this.isDelete = true
    },
    async removed(id, isVariant = false) {
      if (!isVariant) {
        await this.removeAllVariants(id)
        if (this.getErrorRemovingEntityItem.length) return

        this.isDelete = false
        const result =
          this.getEntitiesData[this.entityId].filter(
            item => item.dataId !== id
          ) || []
        this.setEntityData({ entityId: this.entityId, data: [...result] })
      } else {
        await this.removingEntityItem({ entityId: this.entityId, dataId: id })
        if (this.getErrorRemovingEntityItem.length) return

        this.isDeleteVariant = true

        const result = this.getEntitiesData[this.entityId].map(item => {
          if (item.variants && item.variants.length) {
            item.variants = item.variants.filter(
              variant => variant.dataId !== id
            )
          }
          return item
        })

        this.setEntityData({ entityId: this.entityId, data: result })
      }

      this.fetchEntitiesProject({
        projectId: this.$route.params.projectId
      })

      this.fetchEntitiesData({
        entityId: this.entityId,
        filter: this.getFilter
      }).then(() => {
        this.pagination.rowsPerPage = this.getEntitiesData[this.entityId].length
        this.infinity =
          this.getFilterNextTokens[this.entityId] &&
          Object.keys(this.getFilterNextTokens[this.entityId]).length
      })
    },
    async removeAllVariants(id) {
      const parent =
        this.getEntitiesData[this.entityId].find(item => item.dataId === id) ||
        []

      for (const variant of parent.variants) {
        await this.removingEntityItem({
          entityId: this.entityId,
          dataId: variant.dataId
        })
      }
    },
    removedVariant(id) {
      this.removed(id, true)
    },
    async toggleEditVariant(id, status = true) {
      if (!status) {
        this.edit(id, status)
      } else {
        const result =
          this.getEntitiesData[this.entityId].map(item => {
            if (item.variants && item.variants.length) {
              item.variants = item.variants.map(variant => {
                variant.isEdit = false
                if (variant.dataId === id) {
                  variant.isEdit = status
                  this.editItem = { ...variant }
                }
                return variant
              })
            }
            return item
          }) || []

        this.setEntityData({ entityId: this.entityId, data: result })
      }
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    copy(e, text) {
      e.stopPropagation()
      const input = document.createElement('textarea')
      document.body.appendChild(input)
      input.value = text
      input.focus()
      input.select()
      document.execCommand('copy')
      input.remove()
    },
    toggleChecked(id) {
      const result = this.getEntitiesData[this.entityId].map(item => {
        if (item.dataId === id) {
          item.isChecked = !item.isChecked
        }
        return item
      })

      this.setEntityData({ entityId: this.entityId, data: result })
    },
    async infiniteHandler() {
      this.loadInfinity = true
      await this.fetchEntitiesData({
        entityId: this.entityId,
        filter: {
          ...this.getFilter,
          nextToken: this.getFilterNextTokens[this.entityId]
        },
        currentData: this.getEntitiesData[this.entityId]
      })

      this.infinity =
        this.getFilterNextTokens[this.entityId] &&
        Object.keys(this.getFilterNextTokens[this.entityId]).length

      this.loadInfinity = false
    },
    async openPDF(e, dataId, documentId, entityId) {
      e.stopPropagation()
      this.document = null
      this.isPreviewPdf = true
      this.selectedDataId = dataId
      this.selectedEntityId = entityId
      await this.fetchDocument({ documentId })
      this.document = this.getDocument
    },
    resetData() {
      const result = this.getEntitiesData[this.entityId].map(item => {
        item.variants = item.variants.map(variant => {
          variant.isEdit = false
          return variant
        })

        item.isEdit = false
        return item
      })
      this.setEntityData({ entityId: this.entityId, data: [...result] })
    },
    async saveLabel(id, label) {
      const entity = this.getEntitiesData[this.entityId].find(
        item => item.dataId === id
      )

      const data = {
        dataId: entity.dataId,
        entityId: this.entityId,
        dataValue: entity.dataValue,
        dataLabel: label,
        dataDefault: true,
        sourceDocumentDate: entity.sourceDocumentDate,
        sourceDocumentType: entity.sourceDocumentType
      }

      await this.putEntityItem(data)
      if (this.getErrorPutEntityItem.length) return

      const result = this.getEntitiesData[this.entityId].map(item => {
        if (item.dataId === id) {
          item.dataLabel = label

          item.variants = item.variants.map(variant => {
            variant.dataLabel = label
            return variant
          })
        }
        return item
      })

      this.setEntityData({ entityId: this.entityId, data: result })
      this.editLabelDone = true
    },
    resetEditLabelDone() {
      this.editLabelDone = false
    },
    buildSearchData(message) {
      if (message && message.length) {
        this.isSearch = true

        const data =
          this.getEntitiesData[this.entityId].filter(item => {
            if (
              item.dataLabel.toLowerCase().indexOf(message) > -1 ||
              item.dataValue.toLowerCase().indexOf(message) > -1 ||
              item.sourceDocumentType.toLowerCase().indexOf(message) > -1 ||
              item.sourceDocumentDate.indexOf(message) > -1
            ) {
              return item
            }
          }) || []

        this.setEntityData({ entityId: this.entityId, data: data })
      } else {
        this.isSearch = false
      }
    },
    async openPreviewPdf(dataId, documentId) {
      this.document = null
      this.isPreviewPdf = true
      this.selectedDataId = dataId
      this.selectedEntityId = this.entityId
      await this.fetchDocument({ documentId })
      this.document = this.getDocument
    }
  },
  filters: {
    reformatIsMoney(value, is_money) {
      if (is_money == true) {
        const regexp = '[\\d-\ ,.()]' //eslint-disable-line
        const result = [...value.matchAll(regexp)]
        if (result.length != value.length) {
          return value
        }

        // Case 123.123,12
        var count = (value.match(/[,.]/g) || []).length
        if (count > 1) {
          if (value.indexOf('.') < value.indexOf(',')) {
            value = value.replace('.', '')
          } else {
            return value
          }
        }

        // Case 123.456
        var count_dot = (value.match(/[.]/g) || []).length
        var count_comma = (value.match(/[,]/g) || []).length
        if ((count_comma == 0) & (count_dot >= 1)) {
          var splited_value = value.split('.')
          if (splited_value[splited_value.length - 1].length == 3) {
            value = value.replace('.', '')
          }
        }

        var value_int = parseFloat(value.replace(/\s/g, '').replace(',', '.'))
        if (isNaN(value_int)) {
          return value
        } else {
          value = value_int.toLocaleString('fr-FR', {
            style: 'currency',
            currency: 'EUR'
          })
          if (value[0] == '-') {
            value = '- ' + value.slice(1)
          }

          // Case 123 456,00 € to 123 456 €
          var testNoCents = value.substring(value.length - 5, value.length - 2)
          if (testNoCents.localeCompare(',00') == 0) {
            value = value.slice(0, value.length - 5) + ' €'
          }

          return value
        }
      }

      return value
    },
    tooManyInformation(value) {
      var window_width = window.innerWidth

      var maxLengthInformation = 80

      if (window_width < 1536) {
        maxLengthInformation = 80
      }

      return value && value.length > maxLengthInformation
        ? value.substr(0, maxLengthInformation) + '...'
        : value
    },
    tooManyInformationValue(value) {
      var window_width = window.innerWidth
      var fixedValueLength = 25
      var maxLengthInformation = 80

      if (window_width < 1536) {
        fixedValueLength = 50
      } else if (window_width >= 1536 && window_width < 1920) {
        fixedValueLength = 25
      } else if (window_width >= 1920 && window_width < 2560) {
        fixedValueLength = 0
      } else {
        fixedValueLength = -25
      }

      return value && value.length > maxLengthInformation - fixedValueLength
        ? value.substr(0, maxLengthInformation - fixedValueLength) + '...'
        : value
    }
  },
  watch: {
    'getDocument.extractedData': function(newExtractedData) {
      this.extractedData = newExtractedData
    },
    getErrorRemovingEntityItem: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getErrorPutEntityItem: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getErrorEntitiesData: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    changeYear: function() {
      this.infinity =
        this.getFilterNextTokens[this.entityId] &&
        Object.keys(this.getFilterNextTokens[this.entityId]).length
    },
    getSearch: function(message) {
      this.buildSearchData(message)
    },
    isSelect: function(status) {
      if (status && this.getEntitiesData[this.entityId]) {
        this.resetData()
      }
    },
    updatePaginationAndInfinity: function(status) {
      if (status) {
        this.pagination.rowsPerPage = this.getEntitiesData[this.entityId].length
        this.infinity =
          this.getFilterNextTokens[this.entityId] &&
          Object.keys(this.getFilterNextTokens[this.entityId]).length
        this.$emit('updatePaginationAndInfinityDone')
      }
    },
    entityFilter: function(val) {
      this.filter = val
    },
    informationName: {
      immediate: true,
      handler: function(newInformationName) {
        this.localInformationName = newInformationName
      }
    },
    data: {
      immediate: true,
      handler: function(newValue, oldValue) {
        this.$nextTick(function() {
          const element = document.querySelector('.is-scroll-info')

          if (element) {
            element.scrollIntoView({ block: 'center', behavior: 'smooth' })
            element.click()
            element.classList.remove('is-scroll-info')
            this.localInformationName = ''
          }
        })
      }
    }
  },
  updated: async function() {
    this.$nextTick(function() {
      const el = document.querySelector('.company-block.active')
      if (el) {
        const childEl = el.querySelectorAll('.q-table tbody .q-tr')
        if (childEl && childEl.length) {
          childEl[0].scrollIntoView({ block: 'center', behavior: 'smooth' })
        } else {
          el.scrollIntoView({ block: 'center', behavior: 'smooth' })
        }
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.pdf-block {
  height: 100%;
  width: 747px;
  position: fixed;
  z-index: 443333;
  right: 0;
  top: 86px;

  .top {
    background: #fff;
  }
}
</style>
