<template>
  <div :class="{ project: true, 'is-close-messages': !getIsOpen }">
    <q-page-container>
      <TopPanel
        :refreshProcessingButton="refreshProcessingButton"
        @refreshDone="refreshDone"
      />
      <q-page>
        <div class="column" style="height: calc(100vh - 168px)">
          <CompanyBlock
            v-for="(item, index) in getEntitiesProject"
            :last="getEntitiesProject.length - 1 === index"
            :key="item.entityId"
            :company="item"
            :different="different"
            @refreshEntities="refreshEntities"
            @selectedEntityId="setSelectedEntityId"
            :selectedEntityId="selectedEntityId"
          />
          <LoadingOrEmpty
            v-if="!getEntitiesProject.length"
            :loading="getLoadingEntitiesProject"
            :custom-empty="true"
          >
            <template v-slot:customEmpty>
              <div
                class="entities-project-wrapper-empty"
                v-if="done && !getMaterialsByProjectId.length"
              >
                <img
                  :src="
                    require('../assets/icons/upload-cloud-empty-entities-project.svg')
                  "
                />
                <div>Aucune sources ajoutées</div>
                <p>
                  Veuillez choisir des sources au format PDF contenant les
                  informations utiles au projet.
                </p>
                <q-btn class="btn-blue" @click="uploadMaterials">
                  <q-icon left class="upload-icon" />
                  <div class="text-right">Importer des sources</div>
                </q-btn>
              </div>
              <div
                class="entities-project-wrapper-empty"
                v-if="done && getMaterialsByProjectId.length"
              >
                <div>Aucune entité pour ce projet</div>
              </div>
            </template>
          </LoadingOrEmpty>
        </div>
      </q-page>
    </q-page-container>

    <Messages
      :min-width="168"
      :filter="false"
      :messages-by-project-id="true"
      :update-messages-by-project-id="updateMessages"
      @messagesUpdated="messagesUpdated"
    >
      <template v-slot:actions>
        <div class="actions-button">
          <q-btn
            class="btn-new"
            label="Créer un document"
            @click="isCreateDocument = true"
          >
            <q-icon left class="create-document" />
          </q-btn>
          <q-btn
            class="btn-new"
            label="Voir les documents"
            :disable="!getDocumentsByProjectId.length"
            @click="
              $router.push(`/created-documents/${$route.params.projectId}`)
            "
          >
            <q-icon left class="view-create-document" />
          </q-btn>
          <q-btn
            class="btn-new"
            label="Ajouter des sources"
            @click="uploadMaterials"
          >
            <q-icon left class="upload-materials" />
          </q-btn>
          <q-btn
            class="btn-new"
            label="Voir les sources"
            :disable="!getMaterialsByProjectId.length"
            @click="
              $router.push(`/uploaded-materials/${$route.params.projectId}`)
            "
          >
            <q-icon left class="view-upload-materials" />
          </q-btn>
        </div>
      </template>

      <template v-slot:chat-actions>
        <div class="chat-messages-actions">
          <q-form @submit="onSubmit" class="q-gutter-md">
            <q-input
              square
              outlined
              bottom-slots
              v-model="message"
              placeholder="Rédiger un message"
              type="text"
            />
            <q-btn
              type="submit"
              class="btn-send-message"
              :disable="!message || getIsSendingMessage"
            />
          </q-form>
        </div>
      </template>
    </Messages>

    <CreateDocumentModal
      :is-show.sync="isCreateDocument"
      @closeModal="closeCreateDocumentModal"
    />

    <UploadMaterialsModal
      :is-show.sync="isUploadMaterialsModal"
      @closeModal="closeUploadMaterialsModal"
    />
  </div>
</template>

<script>
import CompanyBlock from '@/components/project/CompanyBlock.vue'
import { mapGetters, mapActions } from 'vuex'
import { defaultFilter } from '@/store/modules/projects/state'
const TopPanel = () => import('@/components/project/TopPanel')
const CreateDocumentModal = () => import('@/components/modals/CreateDocument')
const UploadMaterialsModal = () => import('@/components/modals/UploadMaterials')
const Messages = () => import('@/components/global/Messages')
const LoadingOrEmpty = () => import('@/components/global/LoadingOrEmpty')

export default {
  name: 'MainProject',
  components: {
    UploadMaterialsModal,
    CreateDocumentModal,
    LoadingOrEmpty,
    CompanyBlock,
    TopPanel,
    Messages
  },
  data() {
    return {
      message: '',
      isCreateDocument: false,
      isUploadMaterialsModal: false,
      projectId: null,
      updateMessages: false,
      done: false,
      refreshProcessingButton: false,
      different: {},
      selectedEntityId: null
    }
  },
  computed: {
    ...mapGetters('messages', [
      'getIsOpen',
      'getIsSendingMessage',
      'getErrorSendingMessage'
    ]),
    ...mapGetters('documents', [
      'getMaterialsByProjectId',
      'getDocumentsByProjectId',
      'getIsActiveCreateDocument'
    ]),
    ...mapGetters('projects', [
      'getEntitiesProject',
      'getLoadingEntitiesProject',
      'getErrorEntitiesProject',
      'getProject',
      'getLoadingProject',
      'getErrorProject'
    ])
  },
  async created() {
    this.projectId = this.$route.params.projectId
    this.setSearch({ message: '' })
    this.updateFilter({ filter: { ...defaultFilter } })
    this.fetchProject(this.projectId)
    this.fetchEntitiesProject({ projectId: this.projectId })
    this.fetchMaterialsByProjectId({ projectId: this.projectId })
    await this.fetchDocumentsByProjectId({ projectId: this.projectId })
    this.done = true
  },
  methods: {
    ...mapActions('projects', [
      'fetchEntitiesProject',
      'fetchProject',
      'updateFilter',
      'setSearch',
      'resetEntitiesProject'
    ]),
    ...mapActions('messages', ['sendMessageToProject']),
    ...mapActions('documents', [
      'fetchMaterialsByProjectId',
      'fetchDocumentsByProjectId'
    ]),
    async onSubmit() {
      if (this.getIsSendingMessage) return

      if (this.message.trim().length) {
        this.updateMessages = false
        await this.sendMessageToProject({
          projectId: this.projectId,
          message: this.message
        })
        this.updateMessages = true
      }
    },
    closeCreateDocumentModal() {
      this.isCreateDocument = false
    },
    closeUploadMaterialsModal() {
      if (!this.getMaterialsByProjectId.length) {
        this.projectId = this.$route.params.projectId
        this.fetchMaterialsByProjectId({ projectId: this.projectId })
      }
      this.refreshProcessingButton = true
      this.isUploadMaterialsModal = false
    },
    refreshDone() {
      this.refreshProcessingButton = false
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    messagesUpdated() {
      this.message = ''
    },
    uploadMaterials() {
      this.isUploadMaterialsModal = true
    },
    refreshEntities() {
      this.fetchEntitiesProject({ projectId: this.projectId })
    },
    setSelectedEntityId(id) {
      this.selectedEntityId = id
    }
  },
  watch: {
    getErrorEntitiesProject: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getErrorProject: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getErrorSendingMessage: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getEntitiesProject: function(newValue, oldValue) {
      if (oldValue.length) {
        this.different = newValue.filter(
          item1 => !oldValue.some(item2 => item2.entityId === item1.entityId)
        )
        this.different = { ...this.different[0] }
      }
    }
  },
  destroyed() {
    this.resetEntitiesProject()
  }
}
</script>
