<template>
  <q-expansion-item
    v-model="expanded"
    @click="clickEntity(company)"
    ref="entity"
    switch-toggle-side
    expand-separator
    label="Company_1"
    :class="{
      'company-block': true,
      'edit-entity': isEditEntity,
      'is-select': isSelect,
      'is-scroll': company.entityId === different.entityId
    }"
  >
    <template v-slot:header>
      <q-item-section>
        {{ company.entityName }}
      </q-item-section>
      <q-item-section side>
        <div v-show="!isEditEntity" class="row items-center">
          <q-btn
            v-if="years.length"
            label="All"
            :class="{ 'btn-all': true, active: current === 0 }"
            @click="all"
          />

          <div class="pagination-wrapper" v-if="years.length">
            <q-btn class="btn-arrow" v-show="prevButton" @click="prev">
              <q-icon class="arrow-left-icon" />
            </q-btn>

            <q-btn
              v-for="(item, index) in years"
              v-show="
                currentIndex + maxPages + 1 > index && index > currentIndex
              "
              :key="item"
              :label="item"
              :class="{ 'btn-all': true, active: +current === +item }"
              @click="e => selectPage(e, item)"
            />
            <q-btn class="btn-arrow" v-show="nextButton" @click="next">
              <q-icon class="arrow-right-icon" />
            </q-btn>
          </div>

          <!-- default-->
          <div class="actions" v-show="!isAddInfo && !isSelect">
            <q-btn label="Sélectionner" class="btn-white" @click="selectInfo">
              <q-icon class="list-icon" />
            </q-btn>

            <q-btn
              icon-right="add"
              label="Ajouter"
              class="btn-white"
              @click="openAddInfo"
            />

            <q-btn class="btn-white btn-edit" @click="edit">
              <q-icon class="edit-icon" />
            </q-btn>
          </div>

          <!-- show if add info -->
          <div class="actions" v-show="isAddInfo" @click.stop="">
            <q-btn label="Annuler" class="btn-cancel" @click="closeNewInfo" />

            <q-btn
              label="Sauver"
              class="btn-save"
              @click="createNewInfo"
              :disable="isEmptyNewEntity"
            >
              <q-icon class="save-icon" />
            </q-btn>
          </div>

          <!-- show if selection-->
          <div class="actions" v-show="isSelect">
            <q-btn
              label="Annuler la sélection"
              class="btn-cancel"
              @click="cancelSelection"
            />
          </div>
        </div>

        <!-- show if edit entity -->
        <div v-if="isEditEntity" class="row items-center" @click.stop="">
          <AutocompleteEntity
            :edit-label-title="company.entityName"
            @setEditLabelTitle="setEditLabelTitle"
            placeholder="Enter the entity name"
            :max-length="maxLength"
          />
          <q-btn label="Supprimer" class="btn-trash" @click="remove">
            <q-icon class="trash-icon" />
          </q-btn>
          <q-btn label="Sauvegarder" class="btn-save" @click="rename">
            <q-icon class="save-icon" />
          </q-btn>
        </div>
      </q-item-section>
    </template>
    <template v-if="expanded">
      <TableCompany
        :entityId="company.entityId"
        :entityFilter="entityFilter"
        @resetEntityFilter="resetEntityFilter"
        :is-add.sync="isAddInfo"
        :is-select.sync="isSelect"
        :changeYear.sync="changeYear"
        :information-name.sync="informationName"
        @closeSelect="closeSelect"
        :updatePaginationAndInfinity="updatePaginationAndInfinity"
        @updatePaginationAndInfinityDone="updatePaginationAndInfinityDone"
        :selectedEntity="selectedEntityId"
        @updateDataDone="updateDataDone"
      />
    </template>

    <DeleteModal
      v-if="isDeleteEntity"
      :content="contentDeleteEntity"
      :is-show.sync="isDeleteEntity"
      @closeModal="closeDeleteModal"
      @removed="removed"
    />
  </q-expansion-item>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TableCompany from '@/components/project/TableCompany'
const AutocompleteEntity = () =>
  import('@/components/project/AutocompleteEntity')
const DeleteModal = () => import('@/components/modals/Delete')

export default {
  name: 'CompanyBlock',
  components: {
    AutocompleteEntity,
    DeleteModal,
    TableCompany
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    different: {
      type: Object,
      default: () => {}
    },
    last: {
      type: Boolean,
      required: true
    }
  },
  data() {
    const reverse = this.company.availableYear.reverse()
    const maxPages = 4

    const pages_elements = {
      entityFilter: {},
      expanded: this.different?.entityId === this.company.entityId,
      isEditEntity: false,
      isDeleteEntity: false,
      isAddInfo: false,
      isSelect: false,
      maxLength: 100,
      contentDeleteEntity: {
        title: 'Delete entity',
        text: [
          'Are you sure want to delete this entity?',
          'You can’t undo this action.'
        ]
      },
      isEmptyNewEntity: true,
      currentIndex: -1,
      current: 0,
      years: reverse,
      maxPages,
      nextButton: reverse.length > maxPages,
      prevButton: false,
      changeYear: 0,
      updatePaginationAndInfinity: false,
      informationName: '',
      selectedEntityId: null
    }
    return pages_elements
  },
  computed: {
    ...mapGetters('projects', [
      'getNewInfo',
      'getCurrentNewInfoId',
      'getFilter',
      'getSendingEntity',
      'getErrorSendingNewInfo',
      'getEntitiesProject',
      'getFetchAllData',
      'getCloseEntities',
      'getOpenEntities'
    ])
  },
  methods: {
    ...mapActions('projects', [
      'setCurrentNewInfoId',
      'clearNewInfo',
      'addNewInfo',
      'fetchEntitiesData',
      'fetchEntitiesProject',
      'renameEntity',
      'removeEntity',
      'setFilterHandlerUpdateStatus',
      'handlerSetEntitiesProject',
      'fetchAllData'
    ]),
    updateDataDone(new_date) {
      const new_year = new_date.slice(6)
      if (this.years.indexOf(new_year) == -1) {
        this.years.push(new_year)
      }
    },
    closeDeleteModal() {
      this.isDeleteEntity = false
    },
    closeAddInfo() {
      this.isAddInfo = false
    },
    openAddInfo(e) {
      e.stopPropagation()
      // open form new entity
      this.clearNewInfo()
      this.setCurrentNewInfoId(this.company.entityId)
      this.expanded = true
      this.isAddInfo = true
    },
    closeNewInfo(e) {
      e.stopPropagation()
      this.clearNewInfo()
      this.isAddInfo = false
    },
    async createNewInfo(e) {
      e.stopPropagation()
      if (this.isEmptyNewEntity) return

      const data = { ...this.getNewInfo }

      data.entityId = this.getCurrentNewInfoId
      this.$set(this, 'informationName', '')

      await this.addNewInfo(data)

      this.$set(this, 'informationName', data.dataLabel)

      if (this.getErrorSendingNewInfo.length) return

      this.isAddInfo = false
      this.clearNewInfo()
      await this.fetchEntitiesProject({
        projectId: this.$route.params.projectId
      })
      await this.fetchEntitiesData({
        entityId: this.company.entityId,
        filter: this.getFilter
      })
      this.updatePaginationAndInfinity = true
      this.setFilterHandlerUpdateStatus({ status: true })
    },
    updatePaginationAndInfinityDone() {
      this.updatePaginationAndInfinity = false
    },
    closeSelect() {
      this.isSelect = false
    },
    async rename(e) {
      e.stopPropagation()

      const data = {
        entityId: this.company.entityId,
        projectId: this.$route.params.projectId,
        entityName: this.company.entityName.trim()
      }

      if (!data.entityName.length) {
        return
      }

      await this.renameEntity(data)

      if (this.getSendingEntity.length) return
      this.isEditEntity = false

      this.$emit('refreshEntities')
    },
    remove(e) {
      e.stopPropagation()
      this.isDeleteEntity = true
    },
    removed() {
      const data = {
        entityId: this.company.entityId,
        projectId: this.$route.params.projectId
      }

      this.removeEntity(data)
      this.isDeleteEntity = false

      const items = this.getEntitiesProject.filter(
        entity => entity.entityId !== data.entityId
      )
      this.handlerSetEntitiesProject({ items })
      this.setFilterHandlerUpdateStatus({ status: true })
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    prev(e) {
      e.stopPropagation()
      this.currentIndex--
      this.checkPrev()
      this.checkNext()
    },
    checkPrev() {
      this.prevButton = this.currentIndex > -1
    },
    next(e) {
      e.stopPropagation()
      this.currentIndex++
      this.checkNext()
      this.checkPrev()
    },
    checkNext() {
      this.nextButton = !(
        this.currentIndex + this.maxPages >=
        this.years.length - 1
      )
    },
    selectPage(e, year) {
      e.stopPropagation()
      this.current = +year

      if (!this.expanded) {
        this.entityFilter = {
          ...this.getFilter,
          filterDataDocumentYears: [year]
        }
        this.expanded = true
      } else {
        this.fetchEntitiesData({
          entityId: this.company.entityId,
          filter: { ...this.getFilter, filterDataDocumentYears: [year] }
        })
      }

      this.changeYear = this.current
    },
    async all(e) {
      e.stopPropagation()
      this.current = 0

      if (!this.expanded) {
        this.entityFilter = { ...this.getFilter, filterDataDocumentYears: [] }
        this.expanded = true
      } else {
        await this.fetchEntitiesData({
          entityId: this.company.entityId,
          filter: { ...this.getFilter, filterDataDocumentYears: [] }
        })
      }

      this.currentIndex = -1
      this.checkNext()
      this.checkPrev()
      this.changeYear = this.current
    },
    selectInfo(e) {
      e.stopPropagation()
      this.expanded = true
      this.isSelect = true
    },
    edit(e) {
      e.stopPropagation()
      this.isEditEntity = true
    },
    cancelSelection(e) {
      e.stopPropagation()
      this.isSelect = false
    },
    resetEntityFilter() {
      this.entityFilter = {}
    },
    setEditLabelTitle(title) {
      this.company.entityName = title
    },
    clickEntity(company) {
      this.selectedEntityId = company.entityId
      // this.fetchEntitiesData({
      //   entityId: this.entityId,
      //   filter: Object.values(this.filter).length ? this.filter : this.getFilter
      // })
    }
  },
  watch: {
    getNewInfo: {
      handler(newValue) {
        const { dataValue, dataLabel, sourceDocumentDate, sourceDocumentType } = newValue

        if (
          this.getCurrentNewInfoId.length &&
          this.getCurrentNewInfoId !== this.company.entityId
        ) {
          this.expanded = false
          this.isAddInfo = false
        }

        this.isEmptyNewEntity = !(
          dataLabel.trim().length &&
          dataValue.trim().length &&
          sourceDocumentType.trim().length &&
          sourceDocumentDate.trim().length
        )
      },
      deep: true
    },
    company: async function(value) {
      this.years = value.availableYear.reverse()

      this.$nextTick(function() {
        const element = document.querySelector('.is-scroll')
        if (element) {
          element.scrollIntoView({ block: 'center', behavior: 'smooth' })
          element.classList.remove('is-scroll')
        }
      })
    },
    getSendingEntity: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getErrorSendingNewInfo: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    expanded: function(status) {
      if (status === true && this.changeYear != 0) {
        this.entityFilter = {
          ...this.getFilter,
          filterDataDocumentYears: [this.changeYear.toString()]
        }
      } else if (status && this.changeYear == 0) {
        this.entityFilter = {
          ...this.getFilter,
          filterDataDocumentYears: []
        }
      }
      if (!status) {
        this.closeSelect()
      }
    },
    getFetchAllData: function(status) {
      if (status) {
        this.expanded = true
      } else {
        this.expanded = false
      }
    },
    getCloseEntities: function(status) {
      if (status) {
        this.expanded = false
      }
    },
    getOpenEntities: function(status) {
      if (status) {
        this.expanded = true
        this.inSearch = true
      }
    }
  }
}
</script>
